import React from "react"

import "./portal-press-icon.css"

const PortalPressIcon = () => (
  <div className="icon portal-press-icon">
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 209 68"
      style={{ enableBackground: "new 0 0 209 68" }}
    >
      <g>
        <defs>
          <rect id="SVGID_1_" width="209" height="68" />
        </defs>
        <clipPath id="SVGID_2_">
          <use style={{ overflow: "visible" }} />
        </clipPath>
        <path
          class="temporary-dwellings-fill"
          d="M104.5,68C162.21,68,209,52.78,209,34S162.21,0,104.5,0S0,15.22,0,34S46.79,68,104.5,68"
        />
      </g>
    </svg>
    <div>Portal Press</div>
  </div>
)

export default PortalPressIcon
